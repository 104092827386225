import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/globals/layout'
import Seo from '../components/globals/seo'
import Hero from '../components/hero'
import Container from '../components/globals/container'
import BlogCard from '../components/blog-card'

const CategoryTemplate = ({ data }) => {
  const { category, posts } = data

  return (
    <Layout>
      <Seo title={category.name} description={category.description} />
      <Hero
        title={category.name}
        backgroundImage={category.backgroundImage}
        isHeading
      />
      <Main>
        <Grid>
          {posts.nodes.map(post => (
            <BlogCard key={post.slug} {...post} />
          ))}
        </Grid>
      </Main>
    </Layout>
  )
}

const Main = styled(Container)`
  max-width: ${props => props.theme.containerSmallMaxWidth};
`

const Grid = styled.div`
  margin-bottom: 120px;

  ${props => props.theme.mq.tablet} {
    margin: -15px -15px 150px;
    display: flex;
    flex-wrap: wrap;
  }

  a {
    & + a {
      margin-top: 20px;
    }

    ${props => props.theme.mq.tablet} {
      width: calc(50% - 30px);
      flex-shrink: 0;
      margin: 15px;
    }

    ${props => props.theme.mq.desktop} {
      width: calc(33.33% - 30px);
    }
  }
`

export default CategoryTemplate

export const pageQuery = graphql`
  query ($slug: String!) {
    category: contentfulCategory(slug: { eq: $slug }) {
      name
      backgroundImage {
        description
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          width: 2400
          height: 800
          quality: 100
        )
      }
    }
    posts: allContentfulBlogPost(
      filter: { category: { slug: { eq: $slug } } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM, DD YYYY", locale: "es_EC")
        heroImage {
          description
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
      }
    }
  }
`
